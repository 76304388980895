import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface Props {
  children: JSX.Element;
  className?: string;
}

export const BoxCenterContent = (props: Props) => {
  const styles = useStyles();
  return <div className={[styles.card, props.className].join(' ')}>{props.children}</div>;
};
