import React from 'react';
import { navigate } from 'gatsby';
import * as ROUTES from '../constants/routes';
import { BoxCenterContent } from '../ui-components/BoxCenterContent/BoxCenterContent';
import {
  Theme as AugmentedTheme,
  Card,
  CardContent,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';
import { LanguageCode } from '../localization/LanguageCode';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    paddingTop: theme.remSpacing(20),
    textAlign: 'center'
  },
  card: {
    width: 400,
    height: 200,
    margin: theme.remSpacing(0, 6, 6, 6)
  },
  pageTitle: {
    color: theme.colors.oxfordBlue06,
    paddingBottom: theme.remSpacing(4)
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  }
}));

const LanguagePage = () => {
  const styles = useStyles();

  const languageToString = (languageCode: LanguageCode): string => {
    return LanguageCode[languageCode];
  };

  const languageFromString = (string: string): LanguageCode => {
    return LanguageCode[string as keyof typeof LanguageCode];
  };

  const handleChange = (event: React.ChangeEvent<unknown>) => {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value as string;
    const languageCode = languageFromString(value);

    Localization.getInst().changeLanguage(languageCode);

    //reload other components
    navigate(ROUTES.LANGUAGE);
  };

  const languageDisplayName = (languageCode: LanguageCode): string => {
    switch (languageCode) {
      case LanguageCode.en:
        return 'English';
      case LanguageCode.no:
        return 'Norsk';
    }

    return 'unknown';
  };

  return (
    <BoxCenterContent className={styles.container}>
      <Card className={styles.card}>
        <CardContent>
          <Typography variant="h1" className={styles.pageTitle} gutterBottom>
            {Localization.getInst().localizedString(LocalizationKey.changeLanguagePageTitle)}
          </Typography>

          <FormControl component="fieldset" className={styles.formControl}>
            <RadioGroup
              aria-label="language"
              name="language"
              className={styles.group}
              value={LanguageCode[Localization.getInst().getLanguage()]}
              onChange={handleChange}
            >
              <FormControlLabel
                value={languageToString(LanguageCode.en)}
                control={<Radio color="default" />}
                label={languageDisplayName(LanguageCode.en)}
              />
              <FormControlLabel
                value={languageToString(LanguageCode.no)}
                control={<Radio color="default" />}
                label={languageDisplayName(LanguageCode.no)}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    </BoxCenterContent>
  );
};

export default () => <LanguagePage />;
